//BOOTSTRAP
// import "bootstrap";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/tab";
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

//GLOBAl LIBRARIES
import "./libs/splide.js";
// import vhb from "./libs/vhb.js";
// import isotope from './libs/isotope.js';
// import isotope from './libs/scrollreveal.js';
