import Splide from "@splidejs/splide";
/*
 * default init
 */
// new Splide(".splide").mount();

/*
 * for loop
 */
var elms = document.getElementsByClassName("splide");

for (var i = 0; i < elms.length; i++) {
  new Splide(elms[i], {
    perPage: 1,
    // focus: 1,
    gap: "3.5rem",
    omitEnd: true,
    pagination: false,
    mediaQuery: "min",
    breakpoints: {
      768: {
        perPage: 2,
      },
      1200: {
        perPage: 3,
      },
      1440: {
        perPage: 4,
      },
    },
  }).mount();
}

/*
 * foreach init (jQuery + attribute ID)
 */
// $(".splide").each(function () {
//   new Splide("." + $(this).attr("data-id"), {
//     pagination: {
//       el: ".swiper-pagination",
//       clickable: true,
//     },
//   }).mount();
// });
